import React, { useState, useEffect, useContext, useRef } from "react";
import { SERVICES } from "../../utils/ConstantSettings";

//languages:

import { useTranslation } from "react-i18next"; 

import Sidebar from "../../components/sidebar/Sidebar";
import styles from "./ChatBot.module.scss";
import "../../assets/scss/dropdown.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Dropdown from "react-bootstrap/Dropdown";
// import uae_logo from '../../assets/images/uae.png'
// import bahrain_logo from '../../assets/images/bahrain.png'
// import ksa_logo from '../../assets/images/saudi-arabia.png'
// import jordan_logo from '../../assets/images/jordan.png'
// import oman_logo from '../../assets/images/oman.png'
// import qatar_logo from '../../assets/images/qatar.png'
// import globe_logo from '../../assets/images/globe.png'
// import egypt_logo from '../../assets/images/egypt.png'

// import logo from "../../assets/images/Logo.png";
import { AuthContext } from "../../utils/AuthContext";
import './main.css'
import "./SubscriptionPlanModal.css"
import InvoiceScreen from "../Invoice/invoice";
import DataResultsOnboarding from "../DataResultsOnboarding";
import GraphOnboardingResult from "../GraphResultOnboarding";
import LogoOnboarding from "../../assets/SVG/Simpla_Favicon_logo.svg";
import Analyze from "../Analysis";
import {
  CommentIcon,
  UploadFileIcon,
  TablerIcon,
  PlusIconDark,
  SettingIcon,
  LogoutDropdownIcon,
  FeedbackIcon,
  CrossIcon,
  OnboardingStars,
  SimplaLogo,
  GlobeIcon,
  AdviseModuleIcon,
  ProcessModuleIcon,
  AnalyseModuleIcon,
  EncircledCrossExit,
} from "../../assets/SVG/svg";
// import simplaLogo from "../../assets/images/simpla.png";
// import ProfileImage from "../../assets/images/banner.png";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getprofile,
  getchatroom,
  getAllBatches,
  getAnalyticsHistory,
  updateuserInfo,
  updateDisclaimerStatus,
} from "../../services/api";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import ButtonRounded from "../../components/common/Button/ButtonRounded";
import FeedbackModal from "../../components/FeedbackModal";
import JoyrideComponent from "../../components/common/joyRide";
import { ProfileSetting } from "../ProfileSetting/ProfileSetting";
import { useTour } from "../../components/common/joyRide/TourContext";
import Modal from "react-bootstrap/Modal";
import { Button, Spinner } from "react-bootstrap";
import { SetAxiosConfig } from "../../utils/axiosConfig";
import { all_countries } from "../../utils/SetupDropdown";
import { Box, Checkbox, Typography, Button as MuiButton } from "@mui/material";
import axios from "axios";
import PrimaryButton from "../../components/common/Button/PrimaryButton";

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


//redux
import { useDispatch, useSelector } from "react-redux";
import { setDisplaySidebar } from "../../ReduxSlices/SidebarSlice";
import { lazy, Suspense } from "react";
//import MenuIcon from '@mui/icons-material/Menu';
import { disableAllChatMode } from "../../ReduxSlices/ChatModeSlice";
import { setApiCount } from "../../ReduxSlices/ApiCountSlice";
import { useTracking } from "react-tracking";
import { useStopwatch } from "react-timer-hook";

import { setQuestionerFilled } from "../../ReduxSlices/QuestionerStatusSlice";
import { setProfileCompleteModalState, setProfileCompletion, setProfileId } from "../../ReduxSlices/ProfileCompletionSlice";
import { setprofileSettingModalOpen, setprofileSettingModalTab } from "../../ReduxSlices/ProfileSettingModal";
import { DesktopBranding, MobileBranding } from "../../components/common/Branding/SimplaBranding";
import { setUpdateProfileEntity } from "../../ReduxSlices/AccountSettingSlice";
import { setRefetchValue } from "../../ReduxSlices/HistoryRefetchSlice";
import { getProfileCompletionStatus } from "../../services/ModuleApi";

// import {AppBar, Toolbar, IconButton} from "@mui/material"
// import MenuIcon from '@mui/icons-material/Menu'
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';

// nav bar MUI
// Lazy import MUI components
const AppBar = lazy(() => import('@mui/material/AppBar'));
const Toolbar = lazy(() => import('@mui/material/Toolbar'));
const IconButton = lazy(() => import('@mui/material/IconButton'));
const MenuIcon = lazy(() => import('@mui/icons-material/Menu'));

// import { AppBar, Toolbar, IconButton} from "@mui/material";

const ChatBotLayout = ({children}) => {

  const { trackEvent } = useTracking({ component: 'Chatbot' });

  const {t} = useTranslation()

  const encodedImg = `${useSelector((state)=>state.profileImage)}`
  const userImgAws = atob(`${encodedImg.substring(0,encodedImg.length-6)}`)

  const languages = [
    {code: "en", name: "English",nameInEng: "English", countryCode: 'gb' /* great britain */ },
    {code: "ar", name: "العربية",nameInEng: "Arabic", countryCode: 'sa' /* saudi arabia */  },
]



const currentPlan = useSelector((state)=>state.currentPlan.name)


function currentPlanStyleClass(plan){

  if (localStorage.getItem('i18nextLng') === "ar" ){
      if (plan === "free" || plan === "questioner"){
        return "trial-plan-ar"
      }
      else if (plan.includes("Starter")){
        return "starter-plan-ar"
      }
      else {
        return "trial-plan-ar"
      }    
  }
  else{
    if (plan === "free" || plan === "questioner"){
      return "trial-plan-en"
    }
    else if (plan.includes("Starter")){
      return "starter-plan-en"
    }
    else {
      return "trial-plan-en"
    }   
  }

}

const [hideFeedbackBtn, setHideFeedbackBtn] = useState(window.innerWidth <= 750 ? true : false)

useEffect(() => {
    // console.log("hideFeedbackBtn ", hideFeedbackBtn)
    const handleResize = () => {
        //console.log(window.innerWidth)
        if (window.innerWidth <= 750){
            setHideFeedbackBtn(true)
        }
        else {
            setHideFeedbackBtn(false)
        }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



//   function handleFeedbackBtnVisibility(){
//     if (tourState.isTourActive === true &&  tourState.currentStep === 11){
//         return false
//     }
//     else return hideFeedbackBtn
//   }

const SuspenseFallback = (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // width: '100vw',
      height: '100vh',
      background: '#faf5e1',
      paddingBottom: '10%',
    }}
  >
    <Spinner />
  </div>
);

function discoverSimplaSendEmail(sendOption="email"){

  setShowEnterpriseModal(true)

  if (localStorage.getItem("simpla_enterprise_email_sent") == "false"){
      axios.post(`${SERVICES.apiBaseUrl}/api_usage/createPremiumRequest`, {notification_method: `${sendOption}`}, 
        {headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json; charset=UTF-8',

        }})
        .then((res)=>{
          let status;
          try {
            status = JSON.parse(res.data?.object)?.status
            localStorage.setItem("simpla_enterprise_email_sent", status )
          }
          catch(error){
            localStorage.setItem("simpla_enterprise_email_sent", true )
          }

          if (res.data?.message === "Success" || res.data?.message === "success"){
            localStorage.setItem("simpla_enterprise_email_sent", true )
          }
        })
        .catch((error)=>{
          //console.log(error?.response?.data?.error);
          trackEvent({
            event_type: "error", 
            event_name:"Failed to send get-in-touch email request", 
            event_data:{
              error: `${error}`
            }
          })
          if (error?.response?.data?.error === "User has already requested for premium" || error?.response?.status === 400){
            localStorage.setItem("simpla_enterprise_email_sent", true )
          }
        })
  }

  
}

const profileStatus = useSelector((state)=>state.profileCompletion?.profileStatus)
const profileStatusObj = useSelector((state)=>state.profileCompletion)



    // profile complete modal
    const [showProfileCompleteModal, setShowProfileCompleteModal] = useState(false)
    const handleShowProfileCompleteModal = ()=> setShowProfileCompleteModal(true)
    const handleCloseProfileCompleteModal = ()=> {
      setShowProfileCompleteModal(false);
      dispatch(setProfileCompleteModalState(false))
      dispatch(disableAllChatMode());
      navigate("/")
    }


    useEffect(()=>{
      setShowProfileCompleteModal(profileStatusObj.modalOpen)
    }, [profileStatusObj.modalOpen])

    // mui drop down menu
    const [langAnchorEl, setLangAnchorEl] = useState()
    const [openLangMenu, setOpenLangMenu] = useState(false);
    const handleClick = (event) => {
    setOpenLangMenu(true);
    setLangAnchorEl(event.currentTarget);
    };
    const handleCloseLangMenu = () => {
    setLangAnchorEl(null);
    setOpenLangMenu(false)
    };

    // Retrieve values from localStorage
    const firstname = localStorage.getItem('firstname') || '';
    const lastname = localStorage.getItem('lastname') || '';
  
    // Get the first character from both firstname and lastname
    const firstnameAcronym = firstname.charAt(0);
    const lastnameAcronym = lastname.charAt(0);

    // function to set bg color for profile

    function setBGColor(){
      if (window.location.href.includes('invoice')){
        return "#FFBE0A"
      }
      else if (window.location.href.includes('analyse')){
        return "#78CD32"
      }
      else return "#9696FA"
    }

    // want to use more of the module modal pop up:
    const [showModal, setShowModal] = useState(false);
    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    // My plan modal pop up:
    const [showMyPlanModal, setMyPlanShowModal] = useState(false);
    const handleMyPlanModalShow = () => setMyPlanShowModal(true);
    const handleMyPlanModalClose = () => setMyPlanShowModal(false);

    // Enterprise modal pop up:
    const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);
    const handleShowEnterpriseModal = () => {
      setShowEnterpriseModal(true)
    };
    const handleCloseEnterpriseModal = () => setShowEnterpriseModal(false);

    // Simpla USE modal pop up:
    const [showUseModal, setShowUseModal] = useState(false);
    const handleShowUseModal = () => setShowUseModal(true);
    const handleCloseUseModal = () => setShowUseModal(false);


  // redux function to open and close sidebar
  const displayBar = useSelector((state)=>state.displaySidebar.value)
  const questioner_filled = useSelector((state)=>state.questioner_filled)
  const currentPlanObj = useSelector((state)=>state.currentPlan)


  const dispatch = useDispatch()
  
  function handleDisplay(){
    if (displayBar === 'none'){
      //setDisplayBar('block')
      dispatch(setDisplaySidebar('block'))
    }
    else 
    {
      dispatch(setDisplaySidebar('none'))
    } 
  }

  // console.log("lang = " + localStorage.getItem('i18nextLng'))


  SetAxiosConfig();
  const { startTour, endTour, tourState } = useTour();
  const [show, setShow] = useState(false);
  // used to open profile setting modal
  const [showAccSettings, setShowAccSettings] = useState(false);
  const profileSettingModal = useSelector((state)=>state.profileSettingModal)
  const [showOnboarding, setShowOnboarding] = useState(false);
  const handleCloseOnboarding = () => {
    navigate("/");
    startTour();
    setShowOnboarding(false);
    if(localStorage.getItem('show-disclaimer') == true || localStorage.getItem('show-disclaimer') == "true" || !localStorage.getItem('show-disclaimer') || localStorage.getItem("show-disclaimer") == undefined || localStorage.getItem("show-disclaimer") == null ){
      setOpen(true);
    }
  };

//new

useEffect(()=>{
  setShowAccSettings(profileSettingModal.open)
},[profileSettingModal.open, profileSettingModal.tab])
 
  const handleSkipOnboarding = () => {
    setShowOnboarding(false);
    endTour();
  
    
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShowAccSetting = () => setShowAccSettings(true);
  const handleCloseAccSetting = () => {
    setShowAccSettings(false);
    dispatch(setprofileSettingModalOpen(false))
    dispatch(setprofileSettingModalTab(""))
  }


  const [open,setOpen]=useState(false);
  const [toggleActive,setToggleActive]=useState(false);
  const navigate = useNavigate();
  const [profileid, setProfileid] = useState();
  const [feedback, setFeedback] = useState(false);
  const [filter, setFilter] = useState("All");
  const [isChecked,setIsChecked]=useState(false);
  const [search, setSearch] = useState("");
  const filterSetter = (val) => {
    setFilter(val);
  };


//   useEffect(()=>{
//     console.log("tourState: ")
//     console.log(tourState)
//   }, [tourState])

  const searchSetter = (val) => {
    setSearch(val);
  };
      const uselocation = useLocation();

  const navigateToSurvey = () => {
    if (  questioner_filled == false ){
    
      // Access current pathname, search, and hash
      const { pathname, hash } = uselocation;

      // Full current URL
      //let currentURL = "/"
    
      if(!`${pathname}${hash}`.includes("survey")){
              //currentURL = `${pathname}${hash}`
              localStorage.setItem("currentURL", `${pathname}${hash}`)
      }

      navigate("/survey")
    }
    else {
      setShowModal(false)
      setShowUseModal(false)
      setMyPlanShowModal(false)
      discoverSimplaSendEmail()
    }
  };

  const [themeclass, setThemeclass] = useState(
    window.location.pathname.includes("invoice")
      ? "bg-yellow-wrapper"
      : "bg-blue-wrapper"
  );

  const [defaultTab, setDefaultTab] = useState(
    window.location.pathname.includes("invoice") ? "Invoice" : "AI Chat"
  );
  const { data, refetch: refetchprofile } = useQuery(["profiles"], getprofile, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      if (localStorage.getItem("profileid")) {
        setProfileid(localStorage.getItem("profileid"));
        const profile_data = data?.data?.object?.find((profile)=>profile.id == localStorage.getItem("profileid"));
        localStorage.setItem("profile_country", profile_data?.country);

        trackEvent({
          event_type: "click", 
          event_name:"Profile Loaded", 
          event_data:{
            loaded_profile_id: `${profile_data?.id}`
          }
        })
        // Dispatch data to the slice
        dispatch(setUpdateProfileEntity({...profile_data}))
      
      } else {
        if (localStorage.getItem("profileid") == null || localStorage.getItem("profileid") == undefined){
          // log sent right after login
          getProfileCompletionStatus(dispatch, data?.data?.object[0]?.id)
          localStorage.setItem("profileid", data?.data?.object[0]?.id);
          trackEvent({
            event_type: "click", 
            event_name:"User has logged in and Default User Profile has been Loaded", 
            event_data:{
              loaded_profile_id: `${data?.data?.object[0]?.id}`
            }
          })
          }
          
          else{
          trackEvent({
            event_type: "click", 
            event_name:"Profile was Switched", 
            event_data:{
              switch_to_profile_id: `${data?.data?.object[0]?.id}`
            }
          })          
          }

        setProfileid(data?.data?.object[0]?.id);
        localStorage.setItem("profileid", data?.data?.object[0]?.id);
        localStorage.setItem("profile_country", data?.data?.object[0]?.country);

        // Dispatch data to the slice
        dispatch(setUpdateProfileEntity({...data?.data?.object[0]}))
      }
    },
  });

  function dialogContentText(){
    if (questioner_filled == true){
      return `${t("form_already_filled")}`
    }

    else if (questioner_filled == false){
      return `${t("enjoying_simpla")}`
    }
    // else if (apiCounter > 0 && questioner_filled == "false"){
    //   return `${t("unexhauted_uses_left")}`
    // }
  }

  // function disableGetFreeUsesBtn(){
  //   if (questioner_filled == "true"){
  //     return "grey"
  //   }

  //   else if (questioner_filled == false){
  //     return ""
  //   }
  //   // else if (apiCounter > 0 && questioner_filled == false){
  //   //   return "grey"
  //   // }
  // }

  function disableGetFreeUsesBtn(){
    if (questioner_filled == true){
      return "grey"
    }

    else if (questioner_filled == false){
      return ""
    }
  }

  //const [apiCounter, setApiCounter] = useState(`${useSelector((state)=>state.apiCount)}`)  
  const apiCounter = useSelector((state)=>state.apiCount)
  const signOffCredits = useSelector((state)=>state.signOffCredits)

  //console.log("uses left:",apiCounter)

  const headers = new Headers({
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json; charset=UTF-8'

  });



async function questionaireStatus(){
  axios.get(`${SERVICES.apiBaseUrl}/api_usage/getQuestioneerStatus`, {
    headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json; charset=UTF-8'
  }})
    .then((res)=>{
      //console.log(res.data.questioneer_filled)

      let temp = {}
      
      try {
        temp = JSON.parse(res.data)
        localStorage.setItem("questioner_filled", `${temp?.questioner_filled}`)
        dispatch(setQuestionerFilled(temp?.questioner_filled))
      }

      catch (error) {
        //console.log("Unable to parse data")
      }
      
    })
    .catch((error)=>{
      //console.log(error)
      trackEvent({
        event_type: "error", 
        event_name:"Failed to get questionaire (survey form) status (whether it is filled or unfilled)", 
        event_data:{
          error: `${error}`
        }
      })
    })

}


function fetchSimplaEnterpriseMailStatus(){
  axios.get(`${SERVICES.apiBaseUrl}/api_usage/getPremiumRequestStatus`, 
  {headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
  }})
    .then((res)=>{
      let status;
      try {
        status = JSON.parse(res.data?.object)?.status
      }
      catch(error){
        localStorage.setItem("simpla_enterprise_email_sent", false )
      }
      localStorage.setItem("simpla_enterprise_email_sent", status )
    })
    .catch((error)=>{
      trackEvent({
        event_type: "error", 
        event_name:"Failed to get sent status of get-in-touch email", 
        event_data:{
          error: `${error}`
        }
      })
    })
}

useEffect(()=>{
    if (tourState.isTourActive === true && tourState.currentStep === 10){
        setHideFeedbackBtn(false)
    }
    else if ((tourState.isTourActive === false && window.innerWidth <= 750) || window.location.href.includes("survey")) {
        setHideFeedbackBtn(true)
    }
}, [tourState])

// useEffect(()=>{
// console.log(tourState)
// }, [tourState])

  useEffect(()=>{
    // FetchApiCount()
    // .then((res)=>{
    //   //console.log("Api Count Request Successful")
    // }).catch((error)=>{
    //   //console.log("Failed to get Api Count")
    // })
    //questionaireStatus()
    //fetchSimplaEnterpriseMailStatus()
    // if (localStorage.getItem("profileid") != null || localStorage.getItem("profileid") != undefined){
      getProfileCompletionStatus(dispatch, localStorage.getItem("profileid"))
      // }
  }, [])

  useEffect(() => {
    if (localStorage.getItem("onboarding") == "false") {
      setShowOnboarding(true);
      statusUpdateCall();
    }
    else{
      if ( (localStorage.getItem("onboarding") == "true" || localStorage.getItem("onboarding") == true) && (localStorage.getItem("show-disclaimer") == undefined || localStorage.getItem("show-disclaimer") == null || localStorage.getItem("show-disclaimer") == "true" || localStorage.getItem("show-disclaimer") == true)) {
      setOpen(true);
      }
    }
  }, []);


  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  const [prevTab, setPrevTab] = useState("")

  const HandleActiveTabName = (tab) =>{
    if (tab === "AI Chat") {
      return "Advise"
    } else if (tab === "Analyze") {
      return "Analyse"
    } else if (tab === "UploadInvoice" || tab === "Invoice") {
      return "Process"
    }
    else return `${tab}`
  }
  useEffect(()=>{
    //console.log("Active Tab = ", defaultTab)
    handleTabTimer(defaultTab)
  }, [defaultTab])

  const handleTabTimer = (activeTab)=>{

    if (!isRunning){
        //console.log("Timer started for", activeTab)
        start()
        setPrevTab(activeTab)
      }

      else if (isRunning && totalSeconds>0){
        pause()
        //console.log(`${HandleActiveTabName(prevTab)} Module was active for: ${totalSeconds}sec` )
        trackEvent({event_type: "time", event_name: `Time Spent in Module`, event_data: {module: `${HandleActiveTabName(prevTab)}`, time_spent: totalSeconds, unit_time: "Seconds"} })
        reset()
        setPrevTab(activeTab)

      }
  }

  // useEffect(() => {
  //   console.log("chatbot loaded")
  // }, [])

  //console.log("app loaded local")


  const makeStatusChangeCall=()=>{
    axios.post(`${SERVICES.apiBaseUrl}/user/update_disclaimer`,{
    
    },{
      headers:{
        "Authorization":`Bearer ${localStorage.getItem('token')}`
      }
    }).then((res)=>{
      
      localStorage.setItem('show-disclaimer','false');
      setOpen(false);
      
    }).catch((e)=>{
      //console.log(e)
      trackEvent({
        event_type: "error", 
        event_name:"Failed to set show disclaimer status", 
        event_data:{
          error: `${e}`
        }
      })
    })
  }



  const {
    data: chatroomsdata,
    refetch,
    isFetching,
  } = useQuery(
    [
      "chatroom",
      profileid,
      filter === "Favourite" ? `filter=Favourite` : ``,
      search !== "" ? `&search=${search}` : "",
    ],
    window.location.pathname.includes("invoice")
      ? getAllBatches
      : window.location.pathname.includes("analyse")
      ? getAnalyticsHistory
      : getchatroom,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: profileid !== undefined,
    }
  );
  const updateStatus = useMutation(updateuserInfo);
  const statusUpdateCall = () => {
    updateStatus.mutate(
      {
        user: {
          onboarding: true,
        },
      },

      {
        onSuccess: (data, variables, context) => {
          //console.log("update: ", data)
          localStorage.setItem("onboarding", "true");
          setOpen(true)
          getProfileCompletionStatus(dispatch, localStorage.getItem("profileid"))

        //   if (localStorage.getItem("show-disclaimer") == undefined || localStorage.getItem("show-disclaimer") == null || localStorage.getItem("show-disclaimer") == "true" || localStorage.getItem("show-disclaimer") == true){
        //   console.log("opening disclaimer")  
        //   setOpen(true)}
        },
        onError: (error, variables, context) => {
          trackEvent({
            event_type: "error", 
            event_name:"Failed to update user profile data", 
            event_data:{
              error: `${error}`
            }
          })
        },
      }
    );
  };

  const isFirstRender = useRef(true);
  const historyRefetchValue = useSelector((state) => state.historyRefetch?.refetchValue);

  useEffect(()=>{

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
  }
  if (historyRefetchValue) {
    setTimeout(()=> {
      refetch();
    }, 3000)
    dispatch(setRefetchValue(false));
  }
}, [historyRefetchValue]);



  const disclaimerRef=useRef();

  const handleScroll=(e)=>{
   
   
    if(e.target.scrollTop>=500){
        setToggleActive(true);
    }
  }


  const handleFeedbackModal = () => {
    setFeedback(!feedback);
  };

  const profileSetter = (id) => {
    //console.log("swapping profile")
    if (localStorage.getItem("profileid") == null || localStorage.getItem("profileid") == undefined){
      localStorage.setItem("profileid", id);
      trackEvent({event_type:"click",event_name:"Profile Loaded", event_data: {loaded_profile_id: id} })
    }
    else{
    // used when profile was swapped
    trackEvent({event_type:"click",event_name:"profile switched", event_data: {action: "user profile was switched", new_profile_id: id} })
    }
    
    setProfileid(id);
    localStorage.setItem("profileid", id);
    if (window.location.pathname.includes("invoice")) {
      navigate("/invoice");
    } else if (window.location.pathname.includes("analyse")) {
      navigate("/analyse");
    } else {
      navigate("/");
    }
  };
  const location = useLocation();
  useEffect(() => {
    const pathnameParts = location.pathname.split("/");
    const firstPart = pathnameParts[1];
    if (
      firstPart === "chat" ||
      firstPart === "invoice" ||
      firstPart === "" ||
      firstPart === "analyse"
    ) {
      if (profileid !== undefined) {
        refetch();
      }
    }
  }, [defaultTab]);
  const { logout } = useContext(AuthContext);
  const logoutCall = () => {
    logout();
    localStorage.removeItem('show-disclaimer');
    dispatch(setApiCount("0"))
    navigate("/login");
  };
  useEffect(() => {
    if (window.location.pathname.includes("invoice")) {
      ThemeHandler("Invoice");
      setDefaultTab("Invoice");
    } else if (window.location.pathname.includes("analyse")) {
      ThemeHandler("Analyze");
      setDefaultTab("Analyze");
    } else if (window.location.pathname.includes("survey") || window.location.pathname.includes("starterplan")){
      ThemeHandler("No Tab");
      setDefaultTab("No Tab");
    } else {
      ThemeHandler("AI Chat");
      setDefaultTab("AI Chat");
    }
  }, [window.location.pathname]);

  
  const ThemeHandler = (tab) => {
  
    setTimeout(function () {
      if (tab === "No Tab"){
        setThemeclass("bg-blue-inactive-wrapper");
      }
      if (tab === "AI Chat") {
        setThemeclass("bg-blue-wrapper");
      }
      if (tab === "Invoice") {
        setThemeclass("bg-yellow-wrapper");
      }
      if (tab === "Analyze") {
        setThemeclass("bg-green-wrapper");
      }
    }, 100);
  };
  const handleTabSelect = (selectedTab) => {
  
    ThemeHandler(selectedTab);
    
    if (selectedTab === "AI Chat") {
          trackEvent({event_type:"click", event_name:"Module Switched", event_data: {action: "module was changed", module_selected: "Advise"}})
          navigate("/");
    }
    if (selectedTab === "Invoice") {
      dispatch(disableAllChatMode());
      localStorage.setItem("mode", null)
      window.dispatchEvent(new Event('storage'));
      if(!window.location.href.includes("invoice"))
      trackEvent({event_type:"click", event_name:"Module Switched", event_data: {action: "module was changed", module_selected: "Process"}})
    
      navigate("/invoice");
    }
    if (selectedTab === "Analyze") {
      dispatch(disableAllChatMode());
      localStorage.setItem("mode", null)
      window.dispatchEvent(new Event('storage'));
      if(!window.location.href.includes("analyse"))
      trackEvent({event_type:"click", event_name:"Module Switched", event_data: {action: "module was changed", module_selected: "Analyse"}})
      
      navigate("/analyse");
    }
  };

  const navButton = useRef(null)
  const sidebarRef = useRef(null);


  const handleOutsideClick = (event) => {
    if (  sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      dispatch(setDisplaySidebar('none'))
    }
  };

  // useEffect(() => {
  //   if (displayBar === 'block') {
  //     window.addEventListener('click', handleOutsideClick);
  //   }

  //   return () => {
  //     window.removeEventListener('click', handleOutsideClick);
  //   };
  // }, []);


  return (
    <>
  <Suspense fallback={SuspenseFallback}>


  
    {/* navbar will go here */}

    {/* <Suspense fallback={<div></div>}> */}
    <AppBar position="static" style={{
      direction:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "rtl" : "ltr"}`,
      background:'#faf5e1', boxShadow:'none', paddingTop:'2%'}} 
      className="custom-navbar">
      <Toolbar>
        {/* Burger Icon for Drawer */}
        <IconButton ref={navButton} edge="start" color="black" aria-label="menu" onClick={()=> handleDisplay()}>
          <MenuIcon />
        </IconButton>

        {/* SVG Logo */}
        <MobileBranding/>

        {/* User Profile View */}
   
          <div
              style={{
                right:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "4%"}`,
                left:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "4%" : ""}`
            }}
              className={` ${styles.profileBtnWrapper} dropdown-wrapper dropdown-iconLess`}>
           

           <Dropdown
                // style={{ right: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "88vw" : ""}` }}
                >
                <Dropdown.Toggle
                style={{background:setBGColor()}}
                  id="dropdown-basic"
                  className="p-0 profile-dropdown-mobile"
                >
                {
                  userImgAws !== "" ? 
                  <>
                    <img src={userImgAws} alt="profile_image" style={{ width: '60px', height: '60px', borderRadius: '50%', margin: '0%', background: 'white', objectFit:"contain" }} />
                  </> : 
                  <>
                    <h4 style={{margin:'1%', textAlign:'center'}}  >{firstnameAcronym}{lastnameAcronym}</h4>    
                  </>
                }                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className={styles.DropdownWrapperBox}  style={{width:"250px", overflowX:"hidden"}}>
                    
                    {data?.data?.object?.map((pr)=>{
                      var image_index=""
                      if(pr.countr=='Any Other Country'){

                      }
                      else{
                        image_index=all_countries.map((item)=> (item.label?.toLowerCase())).indexOf(`${pr.country?.toLowerCase()}`);
                      }
                    
                         
                      return(
                        <Dropdown.Item
                        onClick={() => {
                          
                          if(pr.id.toString()==localStorage.getItem('profileid')){
                            localStorage.setItem("profile_country", `${pr?.country}`)
                            return
                          }
                          else{
                            localStorage.setItem("profile_country", `${pr?.country}`)
                            profileSetter(pr.id);
                            window.location.reload();
                          }
                          
                          
                          // window.location.reload();
                        }}
                        className={
                          pr?.id == localStorage.getItem("profileid")
                            ? "dropdown-item selected-profile"
                            : "dropdown-item"
                        }
                        key={pr?.id}
                      >
                        <span className="img-wrapper">
                                 {pr.country=='Any Other Country' ? '' : <img src={`https://hatscripts.github.io/circle-flags/flags/${all_countries[image_index]?.code?.toLowerCase()}.svg`} width="48" alt=""></img>}
                          {/* {pr.country =='UAE' ? <img src={uae_logo} alt=""  /> :'' }
                          {pr.country =='KSA' ? <img src={ksa_logo} alt=""  /> :'' }
                          {pr.country =='Bahrain' ? <img src={bahrain_logo} alt='' /> :'' }
                          {pr.country =='Jordan' ? <img src={jordan_logo} alt='' /> :'' }
                          {pr.country =='Oman' ? <img src={oman_logo} alt='' /> :'' }
                          {pr.country =='Qatar' ? <img src={qatar_logo} alt='' /> :'' }
                          {pr.country =='Egypt' ? <img src={egypt_logo} alt='' /> :'' }
                          {pr.country=='Any Other Country' ? <img src={globe_logo}  /> :'' }
                          <img src={ProfileImage} /> */}
                        </span>
                        <span
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '137px',  // Adjust based on your layout
                              }}
                            >
                              {pr?.display_name}
                            </span>
                      </Dropdown.Item>
                      )
                    })}
                  </div>
                  <hr />
                  <h6 style={{
                        fontFamily: 'karla', marginLeft:'1%', 
                        textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`,
                        marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "3%" : ""}`

                        
                        }}> {t('option_switch_lang')} </h6>
                  {
                    languages.map((language)=>{
                        return (<>
                        <Dropdown.Item style={{
                          background: `${localStorage.getItem('i18nextLng')?.toString() === language.code ? "#f0ede4" : ""}`, 
                          width:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "100%" : "100%"}`, 
                          marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "0%" : "0%"}`                        //marginLeft: `${localStorage.getItem('i18nextLng')?.toString() !==  "ar" ? "10%" : "0%"}`
                      }}
                        onClick ={(e) => {localStorage.setItem("i18nextLng", `${language.code}`); window.location.reload();
                      }}>
                        {/* <img src={`https://hatscripts.github.io/circle-flags/flags/${language.countryCode.toLowerCase()}.svg`} width="25" alt=""></img> */}
                          {language.name}
                        </Dropdown.Item>
                        </>)
                    })
                  }
                  <hr/>
                  <Dropdown.Item
                    onClick={() => {
                      dispatch(disableAllChatMode());
                      localStorage.setItem("mode", null);
                      // Manually trigger a storage event
                      window.dispatchEvent(new Event('storage'));
                      navigate("/accountsetup");
                    }}
                  >
                    <PlusIconDark />
                    {t("option_add_acc")}
                  </Dropdown.Item>
                  <Dropdown.Item className="acc-settings" onClick={()=>{handleShow()}}>
                    <SettingIcon />
                    {t("option_edit_profile")}
                  </Dropdown.Item>
                  <hr />
                  <Dropdown.Item onClick={()=>{dispatch(disableAllChatMode()); localStorage.setItem("mode", null); window.dispatchEvent(new Event('storage')); logoutCall();}}>
                    <LogoutDropdownIcon />
                    {t("option_logout")}
                  </Dropdown.Item>
                  
                </Dropdown.Menu>
              </Dropdown>

              {showAccSettings && (
                <ProfileSetting
                  handleClose={handleCloseAccSetting}
                  profiles={data?.data?.object?.filter(
                    (profile) => profile.id == localStorage.getItem("profileid")
                  )}
                  refetch={refetchprofile}
                />
              )}
          </div>

      </Toolbar>
    </AppBar>      
    {/* </Suspense> */}

    




      <div
        style={{direction:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "rtl" : "ltr"}` }}
        className={`${styles.dashboardLayoutWrapper} ${themeclass} bg-yellow`}
      >

        <JoyrideComponent />

{/* <Dialog
  open={showModal}
  onClose={handleModalClose}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "285px",
      borderRadius: "15px",
      background: "#fdfbf5",

    }
  }}
>
  <DialogTitle>


    <div className="close-circle" 
         style={{ 
            position: 'absolute', 
            top: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "4%" : "4%"}`, 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "2.5%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "2.5%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>
                      <div style={{}}>

          <CloseIcon fontSize="normal" onClick={() => setShowModal(false)} style={{marginBottom:"15%"}} />
        </div>

      </div>
  </DialogTitle>
  <DialogContent>

    <div style={{padding:"4% 2%"}}>
    <h6 className="more-uses-heading" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("want_more_btn")}
    </h6>
    <p style={{ fontFamily: 'karla', fontWeight: 600, textAlign: 'center', lineHeight:"25px", paddingTop:"1%"}}>
      {dialogContentText()}
    </p>    
    </div>

  </DialogContent>
  <DialogActions style={{padding:"0% 2% 7% 2%", margin: "0% 3%", gap:"10px"}}>
  <div onClick={navigateToSurvey} className={`more-uses-btn more-uses-dialog-btn ${styles["orange-bg-btn-transition"]}`} style={{  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }}>
      <Typography variant="p" className={`more-uses-btn-text ${styles["orange-bg-btn-text"]}`} style={{fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("get_more_uses")}</Typography>
    </div>

    <div className="more-uses-btn more-uses-dialog-btn" style={{ borderColor: `black`,  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }} onClick={()=>{ setShowModal(false); discoverSimplaSendEmail()}}>
      <Typography variant="p" className="more-uses-btn-text" style={{color: `black`, fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("simpla_enterprise")}</Typography>
    </div>
  </DialogActions>
</Dialog> */}

{/* Subscription plan modal */}
<Dialog
  open={showMyPlanModal}
  onClose={handleMyPlanModalClose}
  fullWidth
  maxWidth="lg"

  PaperProps={{
    sx: {
      minHeight: "max-content",
      borderRadius: "15px",
      background: "#fdfbf5",
    }
  }}
>
  <DialogTitle 
  // style={{display:"flex", justifyContent:"flex-end", height:"60px", }}
  >
    {/* <h6 className="more-uses-heading" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("want_more_btn")}
    </h6> */}

    {/* <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer', position: 'absolute', top: "5%", right: "3%" }} /> */}
        <div className="close-circle" 
        style={{ 
            position: 'absolute', 
            top: `${localStorage.getItem('i18nextLng') === "ar" ? '6.5%' : '6.5%'}`, 
            right: localStorage.getItem('i18nextLng') === "ar" ? 'auto' : '2%', 
            left: localStorage.getItem('i18nextLng') === "ar" ? '2%' : 'auto', 
            transform: 'translateY(-50%)', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            cursor: 'pointer' 
        }}>
        <CloseIcon 
            fontSize="normal" 
            onClick={() => handleMyPlanModalClose()} 
            style={{ margin: 0 }} 
        />
       </div>
       {/* <div 
          onClick={() => handleMyPlanModalClose()} 
          style={{ marginTop: "3px", cursor: 'pointer', }} >
        <EncircledCrossExit />        
       </div> */}

      
  </DialogTitle>

  <DialogContent className="table-dialog-main">

  <div className="table-container">
  <div className={`current-plan ${currentPlanStyleClass(currentPlan)}`} > {t("current_plan")} </div>
  <table className="custom-table">
  <tbody>
    <tr>
      <td className={`column-1 ${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "top-right-radius" :  "top-left-radius"}`}>{t("available_plans")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"} orange-keyword`}>{t("trial")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"} orange-keyword`}>{t("starter")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"} ${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "top-left-radius" :  "top-right-radius"} orange-keyword`}>{t("enterprise")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("price")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("free")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_price")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("sales_team")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("module_usage")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("thirty_free_uses")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_uses")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("unlimited_usage")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("no_of_users")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>1</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_users")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("grouped")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("no_of_profiles")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>1</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_profiles")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("grouped")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("human_advisory")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("na")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_sign_off_credits")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("custom_plan")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("it_support")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("na")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_IT_support")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("custom_priority_support")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("customer_support")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("na")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_customer_support")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("dedicated_support_manager")}</td>
    </tr>
    <tr>
      <td className={`column-1 ${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "bottom-right-radius" : "bottom-left-radius"}`}></td>
     
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>
        <div className="plan-table-custom-button" style={{fontSize: localStorage.getItem('i18nextLng')?.toString() === "ar" ? "12px" : ""}} onClick={()=>setMyPlanShowModal(false)}>{t("continue_trial")}</div>
      </td>

      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>
        <div className="plan-table-custom-button" style={{fontSize: localStorage.getItem('i18nextLng')?.toString() === "ar" ? "12px" : ""}} onClick={()=>{setMyPlanShowModal(false); navigate("/paymentplan")}}>{t("starter_button_text")}</div>
      </td>

      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"} ${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "bottom-left-radius" : "bottom-right-radius"}`}>
        <div className="plan-table-custom-button" style={{fontSize: localStorage.getItem('i18nextLng')?.toString() === "ar" ? "12px" : ""}} onClick={()=>{ setMyPlanShowModal(false);     trackEvent({event_type:"click",event_name:"Discover Simpla Enterprise Button was Clicked", event_data: {action: "user clicked 'Discover Simpla Enterprise Button'"} }); discoverSimplaSendEmail(); }}>{t("discover_enterprise")}</div>
      </td>

    </tr>

    <tr>
      <td className="" style={{opacity:0}}></td>
      <td className="" style={{opacity:0}}></td>
      <td style={{border:"none", fontSize:"10px", fontWeight:"bold" }}>{t("starter_coverage_note")}</td>
      <td className="" style={{opacity:0}}></td>

    </tr>
  </tbody>
</table>
    </div>


  </DialogContent>


</Dialog>

{/* We will be in touch modal */}
<Dialog
  open={showEnterpriseModal}
  onClose={handleCloseEnterpriseModal}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "max-content",
      borderRadius: "15px",
      background: "#fdfbf5",


    }
  }}
>
  <DialogTitle>


    {/* <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer', position: 'absolute', top: "5%", right: "3%" }} /> */}
    {/* <div className="close-circle" 
        style={{ 
            position: 'absolute', 
            top: '11.5%', 
            right: localStorage.getItem('i18nextLng') === "ar" ? 'auto' : '2%', 
            left: localStorage.getItem('i18nextLng') === "ar" ? '2%' : 'auto', 
            transform: 'translateY(-50%)', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            cursor: 'pointer' 
        }}>
        <CloseIcon 
            fontSize="normal" 
            onClick={() => setShowEnterpriseModal(false)} 
            style={{ margin: 0 }} 
        />
       </div> */}
           <div className="" 
         style={{ 
            position: 'absolute', 
            top: "1.5%", 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "1.5%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "1.5%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>

      <div 
          onClick={() => setShowEnterpriseModal(false)} 
          style={{ marginTop: "3px", cursor: 'pointer', }} >
        <EncircledCrossExit />        
       </div>

      </div>
  </DialogTitle>

  <DialogContent>

    <div style={{padding:"5%"}}>

    <h6 className="more-uses-heading" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("in_touch_modal")}
    </h6>

    <p style={{ fontFamily: 'karla', fontWeight: 600, textAlign: 'center', lineHeight:"20px" }}>
      {t("in_touch_modal_description")}
    </p>

    </div>


  </DialogContent>


</Dialog>

{/* What is a "USE" in Simpla Modal  */}
<Dialog
  open={showUseModal}
  onClose={handleCloseUseModal}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "max-content",
      borderRadius: "15px",
      background: "#fdfbf5",
      
    }
  }}
>
  
  <DialogTitle>


    {/* <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer', position: 'absolute', top: "5%", right: "3%" }} /> */}
    {/* <div className="close-circle" 
        style={{ 
            position: 'absolute', 
            top: '4.5%', 
            right: localStorage.getItem('i18nextLng') === "ar" ? 'auto' : '2%', 
            left: localStorage.getItem('i18nextLng') === "ar" ? '2%' : 'auto', 
            transform: 'translateY(-50%)', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            cursor: 'pointer' 
        }}>
        <CloseIcon 
            fontSize="normal" 
            onClick={() => setShowUseModal(false)} 
            style={{ margin: 0 }} 
        />
       </div> */}
             <div className="" 
         style={{ 
            position: 'absolute', 
            top: "1.5%", 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "1.5%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "1.5%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>

      <div 
          onClick={() => setShowUseModal(false)} 
          style={{ cursor: 'pointer', }} >
        <EncircledCrossExit />        
       </div>

      </div>
  </DialogTitle>

  <DialogContent>

    <div style={{padding:"5%"}}>

    <h6 className="more-uses-heading" style={{ paddingTop:"3%", fontWeight: "bold", fontFamily: "karla", textAlign: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "right" : "left"}` }}>
      {t("use_modal_title")}
    </h6>

    <div style={{padding: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "1% 0% 0% 0%" : "1% 0% 0% 0%" }`}}>
      <p style={{ fontFamily: 'karla', fontWeight: 500, lineHeight:"20px", textAlign: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "right" : "left"}` }}>
          {t("use_modal_content_p1")}
        </p>  

        <p style={{ paddingTop:"2%", fontFamily: 'karla', lineHeight:"20px", fontWeight: 500, }}>
          {t("use_modal_content_p2")}
        </p>  

        <div className="module-use-info-div" style={{ paddingTop:"3%", lineHeight:"20px", fontFamily: 'karla', fontWeight: 500, }}>
        <AdviseModuleIcon/> {" "} <b className="module-use-info"  style={{margin: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "0% 1% 0% 0%" : "0% 0% 0% 1%"}`}}>{t("advise_module")}</b> <p style={{margin: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "0% 4% 0% 0%" : "0% 0% 0% 2%"}`}}>{t("advise_use")}</p>
        </div>  

        <div className="module-use-info-div" style={{ paddingTop:"2%", lineHeight:"20px",  fontFamily: 'karla', fontWeight: 500, }}>
        <ProcessModuleIcon/> {" "} <b className="module-use-info" style={{margin: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "0% 1% 0% 0%" : "0% 0% 0% 1%"}`}}>{t("process_module")}</b> <p style={{margin: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "0% 4% 0% 0%" : "0% 0% 0% 2%"}`}}>{t("process_use")}</p>
        </div>  

        <div className="module-use-info-div"  style={{ paddingTop:"2%", lineHeight:"20px", fontFamily: 'karla', fontWeight: 500, }}>
        <AnalyseModuleIcon/> {" "} <b className="module-use-info" style={{margin: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "0% 1% 0% 0%" : "0% 0% 0% 1%"}`}}>{t("analyse_module")}</b> <p style={{margin: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "0% 4% 0% 0%" : "0% 0% 0% 2%"}`}}>{t("analyse_use")}</p>
        </div>  

        <p style={{ paddingTop:"3%", fontFamily: 'karla', fontSize:"large", lineHeight:"20px", fontWeight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "normal" : "bold"}`, paddingRight:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "normal" : "bold"}`, fontStyle:'italic'}}>
          {t("use_modal_content_p3")}
        </p>  
    </div>


    </div>

  </DialogContent>

  <DialogActions style={{padding:"0% 3.5% 7% 3.5%", margin: "0% 3%", gap:"10px"}} >
    <div onClick={()=> {setShowUseModal(false); navigateToSurvey()}} className={`more-uses-btn more-uses-dialog-btn ${styles["orange-bg-btn-transition"]}`} style={{  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }}>
      <Typography variant="p" className={`more-uses-btn-text ${styles["orange-bg-btn-text"]}`} style={{fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("get_more_uses")}</Typography>
    </div>

    <div className="more-uses-btn more-uses-dialog-btn" style={{ borderColor: `black`,  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }} onClick={()=>{ setShowUseModal(false); trackEvent({event_type:"click",event_name:"Discover Simpla Enterprise Button was Clicked", event_data: {action: "user clicked 'Discover Simpla Enterprise Button'"} }); discoverSimplaSendEmail()}}>
      <Typography variant="p" className="more-uses-btn-text " style={{color: `black`, fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("discover_enterprise")}</Typography>
    </div>
  </DialogActions>


</Dialog>

   
        <div className={`${styles.sidebarWrapper}`}>
        
          {/* <div className="overlay">
          <Spinner animation="border" variant="primary" />
        </div> */}
          <Sidebar
            style={{position:"fixed"}}
            ref={sidebarRef}
            profiles={data?.data?.object}
            profileSetter={profileSetter}
            profileid={profileid}
            chatroomsdata={chatroomsdata?.data?.object}
            styleClass="bg-color"
            refetch={refetch}
            loading={isFetching}
            filterSetter={filterSetter}
            filter={filter}
            searchSetter={searchSetter}
            search={search}
            handleCloseOnboarding={handleCloseOnboarding}
          />


          
        </div>


           

         
        <div className={`${styles.rightArea} ${styles.rightSideWrapper}`} >

          <DesktopBranding/>

          <main className={`${styles.mainWrapper} ${ localStorage.getItem("i18nextLng") === "ar" ? "chatbot-layout-margin-ar" : "chatbot-layout-margin-en"} ${window.location.href.includes("survey") ? styles.mainWrapperInactiveTab : ""}`}  style={{overflow: `${ ( window.location.href.includes("invoice") || window.location.href.includes("analyse")  ) ? "auto" : ""}`,}}>

            {/* desktop user profile  */}
          <div
              style={{
                right:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "47px"}`,
                left:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "47px" : ""}`
            }}
              className={`desktop-profile-view ${styles.profileBtnWrapper} dropdown-wrapper dropdown-iconLess`}>

              <Dropdown
                // style={{ right: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "88vw" : ""}` }}
                >
                <Dropdown.Toggle
                style={{background:setBGColor()}}
                  id="dropdown-basic"
                  className="p-0 profile-dropdown-mobile profile-dropdown "
                >
                {
                  userImgAws !== "" ? 
                  <>
                    <img src={userImgAws} alt="profile_image" style={{ width: '60px', height: '60px', borderRadius: '50%', margin: '0%', background: 'white', objectFit:"contain" }} />
                  </> : 
                  <>
                    <h4 style={{margin:'1%', textAlign:'center'}}  >{firstnameAcronym}{lastnameAcronym}</h4>    
                  </>
                }                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className={styles.DropdownWrapperBox}  style={{width:"250px", overflowX:"hidden"}}>
                    
                    {data?.data?.object?.map((pr)=>{
                      var image_index = ""
                      if(pr.countr=='Any Other Country'){

                      }
                      else{
                        image_index=all_countries.map((item)=> (item.label?.toLowerCase())).indexOf(`${pr.country?.toLowerCase()}`);
                      }
                      

                         
                      return(
                        <Dropdown.Item
                        onClick={() => {
                          
                          if(pr.id.toString()==localStorage.getItem('profileid')){
                            localStorage.setItem("profile_country", `${pr?.country}`)
                            return
                          }
                          else{
                            localStorage.setItem("profile_country", `${pr?.country}`)
                            profileSetter(pr.id);
                            window.location.reload();
                          }
                          
                          
                          // window.location.reload();
                        }}
                        className={
                          pr?.id == localStorage.getItem("profileid")
                            ? "dropdown-item selected-profile"
                            : "dropdown-item"
                        }
                        key={pr?.id}
                      >
                        <span className="img-wrapper">
                                 {pr.country=='Any Other Country' ? '' : <img src={`https://hatscripts.github.io/circle-flags/flags/${all_countries[image_index]?.code?.toLowerCase()}.svg`} width="48" alt=""></img>}
                          {/* {pr.country =='UAE' ? <img src={uae_logo} alt=""  /> :'' }
                          {pr.country =='KSA' ? <img src={ksa_logo} alt=""  /> :'' }
                          {pr.country =='Bahrain' ? <img src={bahrain_logo} alt='' /> :'' }
                          {pr.country =='Jordan' ? <img src={jordan_logo} alt='' /> :'' }
                          {pr.country =='Oman' ? <img src={oman_logo} alt='' /> :'' }
                          {pr.country =='Qatar' ? <img src={qatar_logo} alt='' /> :'' }
                          {pr.country =='Egypt' ? <img src={egypt_logo} alt='' /> :'' }
                          {pr.country=='Any Other Country' ? <img src={globe_logo}  /> :'' }
                          <img src={ProfileImage} /> */}
                        </span>
                        <span
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '150px',  // Adjust based on your layout
                              }}
                            >
                              {pr?.display_name}
                            </span>
                      </Dropdown.Item>
                      )
                    })}
                  </div>
                  <hr />
                  <h6 style={{
                        fontFamily: 'karla', marginLeft:'1%', 
                        textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`,
                        marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "3%" : ""}`

                        
                        }}> {t('option_switch_lang')} </h6>
                  {
                    languages.map((language)=>{
                        return (<>
                        <Dropdown.Item style={{
                          background: `${localStorage.getItem('i18nextLng')?.toString() === language.code ? "#f0ede4" : ""}`, 
                          width:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "100%" : "100%"}`, 
                          marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "0%" : "0%"}`                        //marginLeft: `${localStorage.getItem('i18nextLng')?.toString() !==  "ar" ? "10%" : "0%"}`
                      }}
                        onClick ={(e) => {localStorage.setItem("i18nextLng", `${language.code}`); window.location.reload();
                      }}>
                        {/* <img src={`https://hatscripts.github.io/circle-flags/flags/${language.countryCode.toLowerCase()}.svg`} width="25" alt=""></img> */}
                          {language.name}
                        </Dropdown.Item>
                        </>)
                    })
                  }
                  <hr/>
                  <Dropdown.Item
                    onClick={() => {
                      dispatch(disableAllChatMode());
                      localStorage.setItem("mode", null);
                      // Manually trigger a storage event
                      window.dispatchEvent(new Event('storage'));
                      navigate("/accountsetup");
                    }}
                  >
                    <PlusIconDark />
                    {t("option_add_acc")}
                  </Dropdown.Item>
                  <Dropdown.Item className="acc-settings" onClick={()=>{handleShow()}}>
                    <SettingIcon />
                    {t("option_edit_profile")}
                  </Dropdown.Item>
                  <hr />
                  <Dropdown.Item onClick={()=>{dispatch(disableAllChatMode()); localStorage.setItem("mode", null); window.dispatchEvent(new Event('storage')); logoutCall();}}>
                    <LogoutDropdownIcon />
                    {t("option_logout")}
                  </Dropdown.Item>
                  
                </Dropdown.Menu>
              </Dropdown>

              {show && (
                <ProfileSetting
                  handleClose={handleClose}
                  profiles={data?.data?.object?.filter(
                    (profile) => profile.id == localStorage.getItem("profileid")
                  )}
                  refetch={refetchprofile}
                />
              )}
          </div>

          {/* top tabs to navigate to process/advise/invoice */}
          
            <Tabs
              style={{height:"75px", background:"white"}}
              defaultActiveKey={defaultTab}
              id="uncontrolled-tab-example"
              className="mb-3 Tab-control-wrapper"
              onSelect={handleTabSelect}
              activeKey={defaultTab}
            >              
              
              <Tab
                
                eventKey="AI Chat"
                title={
                  <>
                
                    <span className="advise-module-target" >
                      {" "}
                      <CommentIcon />{" "}
                    </span>
                    
                    <div className={`${styles.chatToolTip}`}>{t("advise")}</div>
                  </>
                }
                selected
              >
                {tourState.currentStep < 4 && <Outlet />}
              </Tab>

              <Tab
                eventKey="Invoice"
                title={
                  <>
                    <span>
                      {" "}
                      <UploadFileIcon />
                    </span>
                    <div className={`${styles.uploadToolTip}`}>{t("process")}</div>
                  </>
                }
                className="Upload"
              >
                <div style={{padding: "0vw 1vw"}}>
                {!tourState.isTourActive && <Outlet />}
                </div>
              </Tab>

              <Tab
                eventKey="Analyze"
                title={
                  <>
                    <span>
                      {" "}
                      <TablerIcon />{" "}
                    </span>
                    <div className={`${styles.analyzeToolTip}`}>{t("analyse")}</div>
                  </>
                }
                className="tabler"
              >
                <div style={{padding: "0vw 1vw"}}>
                    {!tourState.isTourActive && <Outlet />}
                </div>
              </Tab>

            </Tabs>            
    

            {!tourState.isTourActive || tourState.currentStep <=3 ? 
                    <div 
                        style={{padding: `${window.location.href.includes('analyse') || window.location.href.includes('invoice') ? "0px 2%" : ""}`}}>
                            {children}
                    </div> : ""
            }


            {tourState.currentStep === 4 && <InvoiceScreen />}
            {tourState.currentStep === 5 && <DataResultsOnboarding />}
            {tourState.currentStep === 6 && <DataResultsOnboarding />}
            {tourState.currentStep === 7 && <Analyze />}
            {tourState.currentStep > 7 && <GraphOnboardingResult />}
            
            <ButtonRounded
              hide_btn={window.location.href.includes('/survey') === true ? true : hideFeedbackBtn}
              buttonIcon={feedback ? <CrossIcon /> : <FeedbackIcon />}
              styleclass={`feedback-btn btn-rounded-sm ${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "arabic-locale-placement" : "normal-locale-placement"}`}
              btnBorder="none"
              onClick={handleFeedbackModal}
            />
            {feedback ? <FeedbackModal /> : null}

          </main>

          
          
        </div>
      </div>
      <Dialog open={open}   sx={{"& .MuiPaper-root":{borderRadius:'16px',background:'#faf5e1',direction: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "rtl" : "ltr"}`,minWidth:{xs:'94vw',md:'850px'},minHeight:'550px'}}} >
    <Box sx={{marginTop:'1.5%', display:'flex',justifyContent:'center'}} >
                    <h4 variant='body1' style={{fontWeight:700,textAlign:'center'}} >  {t("disclaimer_for_simpla")}
                    </h4>
                   
                </Box>
                
              <DialogContent sx={{maxHeight:'400px', direction: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "rtl" : "ltr"}`}} onScroll={handleScroll} ref={disclaimerRef}   >
              
                <Box mt={3} className="disclaimer-box" >
                      <Typography variant="body2" sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > 
                      {t("disclaimer_body")}
                      </Typography>
                </Box>
                <Box mt={3} className="disclaimer-box" >
                    <Typography sx={{textDecoration:'underline',textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > {t("disclaimer_advise")} </Typography>
                    <Typography variant='body2' sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}}  >{t("disclaimer_advise_body")}
</Typography>
                </Box>
                <Box mt={3} className="disclaimer-box" >
                    <Typography sx={{textDecoration:'underline',textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > {t("disclaimer_analyse")} </Typography>
                    <Typography variant='body2' sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}}  >
                      {t("disclaimer_analyse_body")}
                    </Typography>
                </Box>
                <Box mt={3} className="disclaimer-box" >
                    <Typography sx={{textDecoration:'underline',textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > {t("disclaimer_process")} </Typography>
                    <Typography variant='body2' sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}}  >{t("disclaimer_process_body")}</Typography>
                </Box>
                <Box mt={3} className="disclaimer-box" >
                    <Typography sx={{textDecoration:'underline',textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} >{t("disclaimer_general")} </Typography>
                    <Typography variant='body2' sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}}  > {t("disclaimer_general_body")}

</Typography>
                </Box>
                <Box mt={3} className="disclaimer-box" >
                    <Typography sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > {t("help_phrase")} <a> {t("help_email")} </a> </Typography>
              
                </Box>
              </DialogContent>
              <Box className='disclaimer-checkbox-and-button'  mt={3} sx={{  display:'flex',gap:'0.5rem',width:'100%',alignItems:'center'}} >
                <Box  sx={{display:'flex',gap:'0.1rem',alignItems:'center', fontWeight:500}} >
                <Checkbox
                    disabled={toggleActive===true ? false :true}
                    sx={{color:'#FF7420','& .MuiSvgIcon-root':{color:'#FF7420'}}}
                      checked={isChecked===true ? true :false}
                      onClick={()=>setIsChecked(!isChecked)}
                    />
                    
                    {t("skip_disclaimer")}                   
                </Box>
                <MuiButton className="disclaimer-agree-button" disabled={toggleActive===false ? true :false} disableElevation disableFocusRipple disableRipple disableTouchRipple variant='contained' sx={{  letterSpacing:'-0.645888px !important', fontSize:'20px', "&:disabled":{background:'white !important',color:'black !important'}, borderRadius:'18px', fontFamily: 'Karla, sans-serif', width:'210px',height:'40px', textTransform:'none', background:toggleActive==false ? 'white' :'#FF7420', color:toggleActive==false ? 'black' :'white','&:hover':{background:toggleActive==false ? 'white' :'#FF7420'}}}   onClick={(e) => {
                if(isChecked===true){
                      makeStatusChangeCall();
                }
                else{
                  localStorage.setItem('show-disclaimer','false');
                  setOpen(false);
                }
              
              
              
           
              
              }} >
                    {t("understand")}
                </MuiButton>
              {/* <PrimaryButton
              buttonText={
               'I understand'
              }
              
              styleclass={`${toggleActive === false  ? 'primarybtn btn-common-primary mt-auto disclaimerbtn' :''}`}
              icon={undefined}
              
              type={"submit"}
              hasIcon={false}
              size={undefined}
              disabled={toggleActive==false ? true :false}
              onClick={(e) => {
                if(isChecked===true){
                      makeStatusChangeCall();
                }
                else{
                  localStorage.setItem('show-disclaimer','false');
                  setOpen(false);
                }
              
              
              
           
              
              }}
            /> */}
              </Box>
    </Dialog>

          {/* profile completed success modal */}
<Dialog
  open={showProfileCompleteModal}
  onClose={handleCloseProfileCompleteModal}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "285px",
      borderRadius: "20px",
      background: "#fdfbf5",

    }
  }}
>
  <DialogTitle>


    {/* <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer', position: 'absolute', top: "5%", right: "3%" }} /> */}
    {/* <div className="close-circle" 
         style={{ 
            position: 'absolute', 
            top: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "3.5%" : "3.5%"}`, 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "2%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "2%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>
                      <div style={{}}>

          <CloseIcon onClick={() => setShowProfileCompleteModal(false)} style={{fontSize:"18px", marginBottom:"20%"}} />
        </div>

      </div> */}
      <div className="" 
         style={{ 
            position: 'absolute', 
            top: "1.5%", 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "1.5%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "1.5%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>

      <div 
          onClick={() => setShowProfileCompleteModal(false)} 
          style={{ marginTop: "3px", cursor: 'pointer', }} >
        <EncircledCrossExit />        
       </div>

      </div>
  </DialogTitle>
  <DialogContent>

    <div style={{padding:"4% 2% 0% 2%"}}>
    <h6 className="more-uses-heading sign-off-modal-h" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("profile_complete_heading")}
    </h6>
    <p className="sign-off-modal-p" style={{ fontFamily: 'karla', fontWeight: 600, textAlign: 'center', lineHeight:"25px", paddingTop:"1%"}}>
      {t("profile_complete_body1")} <br/> {t("profile_complete_body2")}

    </p>    
    </div>

  </DialogContent>
  <DialogActions style={{padding:"0% 2% 7% 2%", margin: "4% 3% 0% 3%", gap:"10px"}}>
  {/* <div onClick={()=>{setShowProfileStatusModal(false); }} className={`more-uses-btn more-uses-dialog-btn ${styles["orange-bg-btn-transition"]}`} style={{  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }}>
      <Typography variant="p" className={`upgrade-plan-modal-button-text ${styles["orange-bg-btn-text"]}`} style={{color: "#ff5b00", fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("return_to_simpla")}</Typography>
    </div> */}

    <div className="sign-off-modal-btn-black more-uses-dialog-btn profile-complete-modal-btn" style={{ width: "250px", background:"#dfdffd", border: "2px solid #9696fa" }} onClick={()=>{ dispatch(disableAllChatMode()); handleCloseProfileCompleteModal(); navigate("/")  }}>
      <Typography variant="p" className="upgrade-plan-modal-button-text" style={{color: `black`, fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("profile_complete_button_text")}</Typography>
    </div>

  </DialogActions>
</Dialog>

    </Suspense>
    </>
  );
};

export default ChatBotLayout;
